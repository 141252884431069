.nav .menu {
  display: flex;
  position: fixed;
  justify-content: space-around;
  align-items: center;
  top: 0;
  padding: 16px 0;
  width: 100%;
  background: var(--backgroundColor);
  max-width: var(--max-width);
  box-shadow: var(--shadow-2);
  z-index: 1;
}

li.item {
  font-size: 18px;
}

.color-picker {
  display: flex;
  align-items: center;
  height: 100%;
}

.logo {
  /* flex: 1; */
  padding: 0 10px;
}

.item {
  padding: 0 10px;
}
