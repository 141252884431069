.Contact {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  max-width: 750px;
  margin: 0 auto;
  padding: 100px 10px 0;
}

.Contact div {
  background: var(--white);
}
