.footer {
  padding-top: 100px;
}

.footer-links {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

.footer-link {
  margin: 0 15px;
}
