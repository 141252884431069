.Projects {
  padding-top: 100px;
}

.projects-container {
  padding-top: 10px;
  margin: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 100px 20px;
}

.project {
  padding: 20px;
  box-shadow: var(--shadow-4);
  max-width: 500px;
  background: var(--grey-200);
}

.project p {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 125px;
}

.project img {
  border: 1px solid bl;
}

.project-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.projects-container img {
  width: 100%;
}

.project-code-links {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.project-code-links > a {
  width: 100%;
}

.project-site-link {
  width: 100%;
}

.project h4 {
  margin-top: 20px;
}
