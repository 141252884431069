.About {
  padding: 100px 20px 0;
}

.About p {
  margin: auto;
}

.intro {
  font-size: 1.2rem;
  color: var(--primary-800);
}
