.Skills {
  padding-top: 100px;
}

.skills-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: var(--fontColor);
  max-width: 700px;
  padding-top: 10px;
  margin: auto;
}

figure {
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

figure svg {
  font-size: 50px;
}
